.dashboard-main {
  height: 100vh;
  padding: 0px !important;
  background-color: #ffffff;
  display: flex;
  /* justify-content: space-around; */
  flex-direction: row;
  justify-content: space-between;
}

.mid {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 70%;
}

.mid-container {
  margin: 5% 2%;
  /* width: 70%; */
  height: 27%;
  top: 133px;
  left: 208px;
  border-radius: 18px;
  border: 1px;
  /* background: linear-gradient(0deg, #4E8393, #4E8393), */
  /* linear-gradient(180deg, #FDFEFF 52.6%, rgba(254, 255, 255, 0) 100%); */
  /* background: linear-gradient(0deg, #a7fdff, #ffffff),
    linear-gradient(
      0deg,
      color(display-p3 0.8 0.8 0.8),
      color(display-p3 0.91 0.91 0.91)
    ),
    linear-gradient(180deg, #fdfeff 52.6%, rgba(254, 255, 255, 0) 100%),
    linear-gradient(
      180deg,
      color(display-p3 0.992 0.996 1) 52.6%,
      color(display-p3 0.996 1 1 / 0) 100%
    ); */

  background: #fdfeff;

  border: 1px solid #4e8393;
  box-shadow: 0px 4px 4px 0px #00000040;
  display: flex;
  justify-content: space-around;
}

.result-box {
  width: 76px;
  height: 76px;
  top: -67px;
  left: -219px;
  border-radius: 10px;
  border: 1px;
  background: #3d6842;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.result-box p {
  /* font-family: Inter; */
  font-size: 32px;
  font-weight: 800;
  line-height: 39px;
  letter-spacing: 0em;
  text-align: left;
}

.total-conatiner {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
}

.total-heading {
  /* font-family: Inter; */
  font-size: 24px;
  font-weight: 800;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: left;
  color: #3d6842;
}

.right {
  /* background: linear-gradient(180deg, #208424 61.46%, rgba(9, 120, 16, 0) 100%),
    linear-gradient(
      180deg,
      color(display-p3 0.255 0.51 0.2) 61.46%,
      color(display-p3 0.208 0.463 0.145 / 0) 100%
    ); */
  background: #ffffff;
  width: 28%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
}

.schedule-container {
  width: 94%;
  height: 130px;
  top: 133px;
  left: 701px;
  border-radius: 18px;
  background: #e8e9e9;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.schedule-container span {
  /* font-family: Inter; */
  font-size: 24px;
  font-weight: 200;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: left;
  color: #419043;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60%;
}
.date-conatiner {
  width: 86px;
  height: 89px;
  top: -114px;
  left: 353px;
  border-radius: 10px;
  border: 1px;
  background: linear-gradient(0deg, #2d693e, #2d693e),
    linear-gradient(
      0deg,
      color(display-p3 0.239 0.408 0.259),
      color(display-p3 0.239 0.408 0.259)
    ),
    linear-gradient(0deg, #ffffff, #ffffff),
    linear-gradient(0deg, color(display-p3 1 1 1), color(display-p3 1 1 1));
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
}
.date-conatiner span {
  color: #ffffff;
  font-weight: 800;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.dashboard-date {
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  margin-top: 10px;
  color: #3d6842;
}

.chart-container {
  width: 65%;
  /* margin-top: 40%; */
}

.Analytics-container {
  width: 250;
}

.esri-logo-container {
  position: absolute;
  top: 20px;
  right: 20px;
}

.esri-logo {
  width: 120px; 
  height: auto; 
}

.no_show_chart{
  background-color: #EFEFEF;
  padding: 10px;
  border-radius: 10px;
  margin-right: 10px;
}

.heading_no_show{
  margin-left: 80px;
  color: #2d693e;
  font-weight: bold;
  font-size: medium;
  margin-bottom: 5px;
}

.total-number{
  margin-bottom: 0 !important;
}

.day{
  margin-bottom: 0 !important;
}


.date{
  margin-bottom: 0 !important;
  margin-top: 0 !important;
}


