* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Inter, DM Sans;
}

.main-container {
  display: flex;
  background-color: #f8f8f9;
}
main {
  /* padding: 10px; */
  width: 100%;
}

.title {
  font-size: 3rem;
  display: grid;
  place-items: center;
}

/* Sidebar */
.sidebar {
  /* background: rgb(0, 7, 61); */
  background: #ffffff;
  color: #f6f6f6;
  height: 100vh;
  overflow-y: auto;
  width: 20%;
  border-radius: 10px;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
}

.top_section {
  display: flex;
  /* align-items: center; */
  /* justify-content: space-between; */
  padding: 30px 15px;
  /* font-size: 21px; */
}
.logo {
  font-size: 18px;
  line-height: 0;
  /* width: 83px; */
  /* height: 74px; */
  background-color: rgba(255, 255, 255, 1);
  color: #000;
  border-radius: 10px;
  box-shadow: -2px 4px 10px 2px rgba(0, 0, 0, 0.45) inset;
}
.bars {
  width: 30px;
  cursor: pointer;
}
.hide {
  display: none;
}

.search {
  display: flex;
  align-items: center;
  margin: 10px 0;
  height: 30px;
  padding: 10px;
}
.search input {
  border: none;
  margin-left: 10px;
  border-radius: 5px;
  background: rgb(238, 238, 238);

  color: white;
}

.routes {
  /* margin-top: 15px; */
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 10px;
}
.routes .icon {
  width: 25px;
  height: 20px;
  font-size: 22px;
  /* color: ; */
  color: #4e8393;
}
.link {
  display: flex;
  color: #4e8393;
  gap: 20px;
  font-size: 11px;
  font-weight: 800;
  line-height: 13.31px;
  padding: 15px 10px;
  border-right: 4px solid transparent;
  transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
}
.link:hover {
  /* border-left: 2px solid white; */
  /* background: rgb(45, 51, 89); */
  /* border: 1px solid #4e8393; */
  transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
}
.active {
  /* border-left: 2px solid white; */
  /* background: rgb(45, 51, 89); */
  color: #38975e;
  font-weight: 800;
  border-left: 2px solid #38975e;
}
.active svg path {
  fill: #419043 !important; /* Change the fill color as desired */
  /* color: #38975e; */
  /* stroke: #419043 !important; */
}
.link_text {
  white-space: nowrap;
  font-size: 15px;
  align-items: center;
  display: flex;
  cursor: pointer;
}

.menu {
  display: flex;
  color: white;

  padding: 5px 10px;
  border-right: 4px solid transparent;
  transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
  justify-content: space-between;
}
.menu_item {
  display: flex;
  gap: 10px;
}
.menu_container {
  display: flex;
  flex-direction: column;
}
.menu_container .link {
  padding-left: 20px;
  border-bottom: #fff 0.5px solid;
}
