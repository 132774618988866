.logo-background {
  height: 74px;
  
  background-color: rgba(255, 255, 255, 1);
  color: #000;
  border-radius: 18px;
  box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.45) inset;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Heading {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 70%;
  align-items: center;
}

.Heading p {
  color: #089237;
}

.logout-container {
  display: flex;
  /* justify-content: s; */
  padding: 15px 20px;
  flex-direction: row;
  cursor: pointer;
}

.logout-container p {
  color: #4e8393;
  /* font-family: Inter; */
  font-size: 15px;
  font-weight: 800;
  line-height: 13px;
  letter-spacing: 0em;
  text-align: left;
  margin-left: 10px;
}

.routes,
a:focus {
  color: #089237 !important;
  text-decoration: none !important;
}

.routes,
a:hover {
  color: #4e8393 !important;
  text-decoration: none !important;
}

a:active {
  /* color: #089237 !important; */
  text-decoration: none !important;
}


/* .sidebar{
  width:255px !important
} */

