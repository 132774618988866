thead {
  /* display: block; */
  position: sticky;
  top: 0;
  /* background-color: #f2f2f2; */
  z-index: 1;
  border-radius: 10px;
}
/* 
tbody {
  border-collapse: collapse;
} */

td {
  padding: 15px;
}

.schedule-main {
  width: 95%;
  height: calc(100vh - 150px);
  background-color: #ffffff;
  margin: 2% auto;
  color: #ffffff;
  overflow-y: scroll;
  border-radius: 10px;
  padding: 0px 15px;
}

.schedule-table {
  width: 100%;
}

.table-no-border {
  border-collapse: separate;
  border: none;
  border-spacing: 0 1em;
}

.table-head {
  height: 51px;
  width: 100%;
  box-shadow: 0px 4px 4px 0px #00000040;
  background: #ffffff;
  border: none;
  font-size: 15px;
  text-align: center;
  color: #00000040;
}

.table-body {
  font-size: 15px;
  text-align: center;
  font-weight: 500;
  /* background: #419043; */
  color: #000000;
}

.repeat-edit-button {
  padding: 10px 12px;
  background: #419043;
  border-radius: 8px;
  border: none;
  /* font-family: Inter; */
  font-size: 14px;
  color: #fff;
  /* font-weight: 700; */
  line-height: 10px;
  letter-spacing: 0em;
  /* text-align: left; */
  margin-left: 10px;
  cursor: pointer;
  transition-duration: 0.5s;
  border: 1px solid #efefef;
}

.repeat-edit-button:hover {
  /* background: #419043; */
  box-shadow: 2px inset;
  /* color: #e5e5ea; */
  border: 1px solid #38975e;
  box-shadow: 0 25px 25px rgba(0, 0, 0, 0.15);
}

.button-div {
  display: flex;
  width: 100%;
  justify-content: space-between;
  /* margin: 10px 0px; */
  /* position: sticky; */
  padding: 10px;
  height: 50px;
}

.search-box {
  font-size: 15px;
  padding: 6px 19px;
  border: 1px solid #c1c1c1;
  background: #efefef;
  width: 9.8em;
  border-radius: 10px;
  transition: 0.2s;
}

.search-box:hover {
  border-color: #aaaaaa;
}
/* 
.search-box:focus-within {
  border-color: #FF0080;
  box-shadow: 0 0 0 5px rgba(255, 0, 128, 0.40);
} */

input {
  /* font-family: Proxima Nova; */
  letter-spacing: -0.2px;
  font-size: 15px;
  border: none;
  color: #323232;
}

button:hover {
  cursor: pointer;
}

input:focus {
  outline: none;
}

input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

.clear:not(:valid) ~ .search-clear {
  display: none;
}

thead th {
  /* background-color: #eee; */
  font-weight: bold;
  padding: 10px;
}

tbody tr {
  border-radius: 10px;
  clip-path: border-box;
  border: 2px solid red;
  border-color: red;
}

tbody td {
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

.rs-dropdown-open ul {
  z-index: 100000 !important;
}

.rs-btn:hover,
.rs-btn:focus {
  background-color: #419043 !important;
}

.rs-input-group:not(.rs-input-group-disabled).rs-input-group-focus,
.rs-input-group:hover,
.rs-input-group:focus-within {
  outline: 1px solid #38975e !important;
  border-color: #38975e !important;
}
