.header {
  height: 67px;
  background: #f8f8f9;
  width: 100%;
}

.header-container {
  display: flex;
  padding: 10px 30px;
}

.notification-div {
  width: 50px;
  height: 50px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)),
    linear-gradient(
      0deg,
      color(display-p3 0 0 0 / 0),
      color(display-p3 0 0 0 / 0)
    ),
    linear-gradient(0deg, #f3f3f3, #f3f3f3),
    linear-gradient(
      0deg,
      color(display-p3 0.953 0.953 0.953),
      color(display-p3 0.953 0.953 0.953)
    );
  /* border: 1px solid #000000; */
  box-shadow: 0px 4px 4px 0px #00000040 inset;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.notification-number {
  color: #419043;
  font-weight: 800;
  left: 10px;
}

.custom-select {
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 10px 20px;
}

/* Reset default select styles */
.custom-select select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 200px; /* Adjust width as needed */
  cursor: pointer;
  font-size: 16px; /* Adjust font size */
}

/* Style the arrow icon */
.custom-select::after {
  /* content: '\25BC';
    position: absolute; */
  /* top: 50%; */
  /* right: 10px; */
  /* transform: translateY(-50%); */
}

/* Style select on focus */
.custom-select select:focus {
  outline: none;
  border-color: #999;
}

.location_heading{
  line-height:40px !important;
  margin-right: 10px !important;
  font-weight:800 !important ;
  font-size: 15px !important;

}
