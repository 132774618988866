@import "rsuite/dist/rsuite.css";
.pagination {
  display: flex;
  list-style: none;
  padding: 0;
  margin: auto 0;
}

.pagination li {
  display: inline-block;
  margin-right: 10px;
}

.pagination a {
  color: #089237;
  cursor: pointer;
  text-decoration: none;
  padding: 8px 12px;
  border: 1px solid #089237;
  border-radius: 4px;
}

.pagination a:hover {
  background-color: #089237;
  color: #fff !important;
}

.rs-anim-fade.rs-anim-in {
  z-index: 10000;
}
.reports {
  font-size: 25px;
  line-height: 22px;
  font-family: inherit;
  font-weight: bolder;
  color: black;
  margin-top: 15px;
}

.custom-modal .p-dialog-content {
  background-color: white; /* Set background color */
  color: black; /* Set text color */
  border-radius: 8px; /* Set border radius */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); /* Add box shadow */
}

