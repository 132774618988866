.users-main {
  display: flex;
  /* justify-content: space-between; */
  width: 95%;
  justify-content: center;
  /* align-items: center; */
  margin: 2% auto;
  background: #fff;
  border-radius: 8px;
  height: 65vh;
  height: calc(100vh - 100px);
  color: #000000;
  overflow-y: scroll;
  border-radius: 10px;
}

.col1-add-users-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 50%;
  align-items: center;
  /* height: 100vh; */
}
/* .col2-add-users-container{
    display: flex;
    justify-content: center;
    width: 50%;
} */

.col2-add-users-container {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
  width: 50%;
  padding: 20px 5%;
  align-items: center;
}

.input-container-add-user {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  gap: 20px;
  color: #000000;
  height: 2.5rem;
}

.input-container-add-user input {
  width: 50%;
  height: 35px;
  background-color: #efefef;
  padding: 5px;
  border-radius: 10px;
}

.column1-input {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
}


.address_input{
  width:"80% !important"
}
