.users-main {
  display: flex;

  /* justify-content: space-between; */
  width: 95%;
  justify-content: center;
  /* align-items: center; */
  margin: 2% auto;
  background: #000000;
  border-radius: 8px;
  height: 65vh;
  height: calc(100vh - 100px);
  color: #ffffff;
  overflow-y: scroll;
  border-radius: 10px;
}

.col1-add-users-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 50%;
  align-items: center;
  /* height: 100vh; */
}
/* .col2-add-users-container{
    display: flex;
    justify-content: center;
    width: 50%;
} */

.col2-add-users-container {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
  width: 50%;
  padding: 20px 5%;
  align-items: center;
}

.input-container-add-user {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 20px;
  color: #fff;
  height: 2.5rem;
}

.input-container-add-user input {
  width: 50%;
  height: 35px;
}

.column1-input {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
}

.Button-conatiner {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.Button-Custom {
  border: 1px solid #efefef;
  border-radius: 10px;
  padding: 8px 15px;
  margin-left: 10px;
  font-size: 15px !important;
}

/* .css-b62m3t-container {
  width: 50%;
} */

.css-13cymwt-control {
  width: 100%;
}

.add_button {
  border-radius: 5px;
  padding: 12px 12px 12px 12px;
  border: none;
  font-size: 16px;
  color: #fff;
  line-height: 6px;
  letter-spacing: 0em;
  /* text-align: left; */
  /* margin-left: 10px; */
  cursor: pointer;
  transition-duration: 0.5s;
  border: 1px solid #efefef;
  background-color: green;
  width: 150px;
  height: 40px;
}

.add_card {
  margin: 30px;
  background-color: white;
  border-color: black;
  border-radius: 5px;
  padding: 20px;
}

.page-title {
  font-family: open sans;
  margin: 30px;
  color: #1a1a1a;
  font-size: 25px;
  text-transform: uppercase;
  font-weight: 800;
}
/* 
.saving_buttons {
  text-align: right;
  width: 100%;
} */

.form_input {
  display: flex;
  flex-direction: row;
  color: black;
  font-size: 15px;
  margin-bottom: 10px;
}
.form_placholder {
  height: 35px;
  border: 1px solid grey;
  border-radius: 3px;
  padding-left: 10px;
}
.home_input {
  width: 300px;
}

.work_input {
  width: 100%;
}

.edit_form_control {
  background-color: lightgray;
}

.tranport_datepicker_input {
  border: 1px solid gainsboro;
  border-radius: 6px;
  height: 39px !important;
  width: 100% !important;
}

.transport_date {
  display: flex;
  flex-direction: column;
}
