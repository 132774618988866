.roster-main {
  display: flex;
  width: 95%;
  flex-direction: column;
  margin: 2% auto;
  background: #fff;
  border-radius: 8px;
  height: 85vh ;
  color: #000000;
  overflow-y: scroll;
  border-radius: 10px;
  padding: 10px 20px 10px 20px;
}

.roster-input-div-main {
  width: 100%;
  top: 304px;
  left: 280px;
  border-radius: 8px;
  background: #d9d9d940;
  margin-top: 30px;
  padding: 10px 10px 20px 10px;
  border: 2px solid #d9d9d940;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.roster-input-div {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin-top: 5px;
}
.roster-input-div p {
  font-size: large;
  margin: 0;
}

.roster-input-div input {
  width: 60%;
  height: 35px;
  padding: 5px 10px;
  font-size: 15px !important;
  color: #000000;
}

.review-container {
  height: 100%;
  /* font-family: Inter; */
  font-size: 13px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  background: #f4fffa;
  border-radius: 10px;
  color: #000000;
}

.review-roster {
  width: 100%;
}

.pragraph-heading {
  /* font-family: Inter; */
  font-size: 24px;
  font-weight: 600;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: left;
  /* color: #3d6842; */
  margin: 5px 0px;
}

.progress-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 50px;
  margin: 20px auto;
  font-size: 24px;
  flex-direction: column;
}

.dot {
  width: 27px;
  height: 25px;
  border-radius: 50%;
  background-color: lightgray;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.dot.active {
  background-color: green;
}

.line {
  height: 2px;
  width: 30px;
  background-color: lightgray;
}

.react-datepicker-wrapper input {
  height: 35px;
  font-size: 15px;
  padding-left: 10px;
  width: 30%;
  margin-top: ;
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border: 1px solid #ccc;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.roster-input-div .css-b62m3t-container {
  width: 25%;
  height: 30px;
  font-size: 15px;
}

.roster-input-div .css-1nmdiq5-menu {
  /* background-color: #d9d9d940; */
  color: #000000;
}

.css-13cymwt-control {
  height: 40px;
}

.step_two_input {
  width: 80% !important;
}


/* create_ride.css */
.roster-input-div-main {
  font-size: 14px; /* Adjust font size */
}

.roster-input-div {
  margin-bottom: 10px; /* Reduce margin between input fields */
}

.roster-input-div p {
  margin-bottom: 5px; /* Reduce margin below labels */
}

.roster-input-div input[type="text"] {
  padding: 8px; /* Reduce padding of input fields */
}


.rs-input{
  z-index: 0 !important;
}
.pickup_time_edit{
  border-color: #ccc;
}